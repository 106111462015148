import React from 'react';

import './holder_codes_table.css';

import holder_codes from 'data/nb/holder_codes.json'

export default class HolderCodesTable extends React.Component {
  /*
   * Table describing HOLDER field codes for NB layer.
   */
  render() {
    return (
      <React.Fragment>
        <table id="holder-codes-table">
          <tr className="holder-codes-tr">
            <th className="holder-codes-th">Holder Code</th>
            <th className="holder-codes-th">Code Description</th>
            <th className="holder-codes-th">Admistration Act</th>
            <th className="holder-codes-th">Definition</th>
          </tr>
          {holder_codes.map(function(item) {
            return (
              <tr className="holder-codes-tr" id={"nb-holder-code-" + item.code}>
                <td className="holder-codes-td">{item.code}</td>
                <td className="holder-codes-td">{item.description}</td>
                <td className="holder-codes-td">{item.act}</td>
                <td className="holder-codes-td">{item.definition}</td>
              </tr>
            );
          })}
        </table>
      </React.Fragment>
    );
  }
}
