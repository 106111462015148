import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';

import App from './App';
import About from './about/about'
import LayerHelpPage from './layer_help/layer_help'

import './index.css';

const Routing = () => {
  return(
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/about" component={About} />
        <Route path="/layer-help" component={LayerHelpPage} />
        <Route path="*" component={() => (<div>Page Not Found </div>)} />
      </Switch>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);
