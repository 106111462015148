import mapboxgl from '!mapbox-gl';  // eslint-disable-line import/no-webpack-loader-syntax

export default class CLGeolocateControl extends mapboxgl.GeolocateControl {
  constructor(...args) {
    return super({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    });
  }
}
