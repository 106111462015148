import React from 'react';

import './about.css'

class About extends React.Component {
  /*
   * About page, containing author and licence info.
   */
	render() {
		return (
      <div className="about-page">
        <h1>Canadian Crown Land Map</h1>
          <br/>
          <h2 id="about">About</h2>
            <p>
              © Matthieu Hughes {new Date().getFullYear()}<br/><br/>
              This is a passion project attempting to aggregate various Canadian Crown land datasets and display them in a usable way. 
              It may be useful to anyone who likes to hike, paddle, swim, bike, off-road, ski, climb, camp, hunt, trap, fish, forage, bird watch, or otherwise recreate on public land. 
              Please obey local regulations regarding permits, licences, restrictions, etc.
              <br/><br/>
              This site is a work in progress and, as such, does not have data for all provinces yet. Some datasets can also be difficult to find and process, so please feel free to reach out if you can help in this regard.
              <br/><br/>
              <a href="mailto:matt@crownlandmap.ca">Contact the author</a> with any bug reports, questions, or suggestions.
              <br/><br/>
            </p>
          <h2 id="licence_info">Licence Information</h2>
            <p>
              All data is for informational purposes only and is provided "as is" without warranty of any kind, express or implied. crownlandmap.ca is not an official source of information and has no affiliation with any government entity.<br/><br/>
              Contains information licensed under the <a href="https://www2.gov.bc.ca/gov/content/data/open-data/open-government-licence-bc">Open Government Licence – British Columbia</a>.<br/>
              Contains information licensed under the <a href="http://www.snb.ca/e/2000/data-E.html">Open Government Licence – New Brunswick</a>.<br/>
              Contains information licensed under the <a href="https://novascotia.ca/opendata/licence.asp">Open Government Licence – Nova Scotia</a>.<br/>
              Contains information licensed under the <a href="https://www.princeedwardisland.ca/en/information/finance/open-government-licence-prince-edward-island">Open Government Licence – Prince Edward Island</a>.<br/>
              <br/>

              Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a><br/>
            </p>
                </div>
    );
	}
}

export default About;
