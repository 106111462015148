import React from 'react';

import {default as NBHolderCodesTable} from './nb/holder_codes_table';

import './layer_help.css';


export default class LayerHelpPage extends React.Component {
  /*
   * Help page for New Brunswick data.
   */
  render() {
    return (
      <div className="layer-help-page">
        <h1>Layer Information</h1>
        <br/>

        <h1 id="nb">New Brunswick</h1>
        <h2 id="holder-codes-header" className="holder-codes-header">Holder Codes Glossary</h2>
        <NBHolderCodesTable />
      </div>
    )
  }
}
