import React from 'react';

export default class ValueWithInfoLink extends React.Component {
  /*
   * React component to construct an element with an info symbol ("i" in a circle) after the value.
   *
   * Props:
   *   value: What to render before the info symbol.
   *   href: Where the info symbol should link to.
   */
  render() {
    return (
      <React.Fragment>
        {this.props.value}
        &ensp;
        <a
          className="blue-link-with-no-decor"
          href={this.props.href}
          target="_blank"
          rel="noreferrer"
        >
          &#9432;
        </a>
      </React.Fragment>
    );
  }

}

