import React from 'react';

export default function ValueWithUnits(props) {
  /*
   * React component to construct an element with units
   * e.g. 17 km^2
   *
   * Props
   *   value: value to display
   *   units: units to display
   *   sup: superscript to display after units (e.g. "2" for km^2)
   */

  const pretty = props.value.toLocaleString(
    undefined, {maximumFractionDigits: 2}
  )
  return (
    <React.Fragment>
      {pretty} {props.unit}{props.sup ? <sup>{props.sup}</sup> : ''}
    </React.Fragment>
  );
}

