import ReactDOM from 'react-dom';
import mapboxgl from '!mapbox-gl';  // eslint-disable-line import/no-webpack-loader-syntax

import AreaPopupContent from './components/area_popup_content'
import ValueWithUnits from './components/value_with_units'
import layer_info from 'data/layer_info.json'

import get_custom_fields from './get_custom_fields'

import './area_popup.css';


export default class AreaPopup extends mapboxgl.Popup {
  constructor(mouse_event, ...args) {
    /*
     * Constructs a popup with area information from the vector layer.
     *
     * Parameters:
     * -----------
     * mouse_event:
     *   mapboxgl MapMouseEvent with a vector layer specified as the target.
     * ...args:
     *   args to pass to super() constructor.
     */
    const layer_id = mouse_event.features[0].layer.id;
    // XXX Bit of a hack, as long as we keep naming layers
    // with the province code as the first two letters this will work
    const prov_code = layer_id.slice(0, 2)
    var feature_properties = mouse_event.features[0].properties;

    // Build up an array of area information to display
    // Each row contains [key, value] for display in a table
    var area_info_primary = []

    // Get coordinates
    const coordinates = mouse_event.lngLat.lat.toFixed(6) + ', ' + mouse_event.lngLat.lng.toFixed(6)
    area_info_primary.push(['Coordinates', coordinates])

    // Extract ID information from properties
    const layer_obj = layer_info[prov_code].layers.find(({source_name}) => source_name === layer_id)
    const id_field = layer_obj.id_field
    if (Object.keys(id_field).length) {
      area_info_primary.push([id_field.label, feature_properties[id_field.field]])
    }

    // Get the layer name
    const layer_name = layer_obj.layer_name.replace('-', ' ')

    // Build up field list
    const fields = layer_obj.fields
    fields.forEach(function(field) {
      if (field.field in feature_properties && feature_properties[field.field] !== null) {
        area_info_primary.push([field.label, feature_properties[field.field]])
      }
    });

    // Get custom fields
    var custom_fields = get_custom_fields(layer_id, feature_properties)
    custom_fields.forEach((item) => {
      area_info_primary.push(item)
    })

    // Area info in hectares
    const area_field = layer_obj.area_field
    if (area_field) {
      var area_area = feature_properties[area_field.field]
      if (area_area) {
        // Convert to hectares
        if (layer_obj.area_field.unit === 'sq_m') {
          area_area /= (100. * 100.)
        }
        // Convert to square km if more than 1000 ha (10 sq km)
        var area_str = ''
        if (area_area > 1000.) {
          area_str = (
      <ValueWithUnits
        value={area_area / 100.}
        unit="km"
        sup="2"
      />
          );
        }
        else {
    area_str = (
      <ValueWithUnits
        value={area_area}
        unit="ha"
      />
          );
        }
        area_info_primary.push([area_field.label, area_str])
      }
    }

    // Get extra properties to display
    var area_info_secondary = []
    const extra_fields = layer_obj.extra_fields
    extra_fields.forEach(function(field) {
      if (field.field in feature_properties && feature_properties[field.field] !== null) {
        area_info_secondary.push([field.label, feature_properties[field.field]])
      }
    });

    // Render the component
    const popup_element = document.createElement('div');
    const popup_component = (
      <AreaPopupContent
        layer_name={layer_name}
        area_info_primary={area_info_primary}
        area_info_secondary={area_info_secondary}
      />
    );
    ReactDOM.render(popup_component, popup_element);

    var popup_obj = super({
      closeButton: true,
      closeOnClick: true,
      maxWidth: "auto"
    })
    return popup_obj
      .setLngLat(mouse_event.lngLat)
      .setDOMContent(popup_element)
  }
}
