import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import './map_navbar.css';

const MapNavbar = ({ defaultProvChecked, provChecked, handleProvChecked }) => {
  return (
    <Navbar bg="light" data-bs-theme="light">
      <Container className="d-flex justify-content-left">
        <Form>
          <Row className="align-items-center">
            <Col xs="auto map-navbar-form">
              <Form.Label>Select Province:</Form.Label>
            </Col>
            <Col xs="auto map-navbar-form">
              <Form.Select
                defaultValue={defaultProvChecked}
                onChange={handleProvChecked}
                size="sm"
              >
                <option value="bc">British Columbia</option>
                <option value="nb">New Brunswick</option>
                <option value="ns">Nova Scotia</option>
                <option value="pe">Prince Edward Island</option>
              </Form.Select>
            </Col>
          </Row>
        </Form>
      </Container>
    </Navbar>
  );
};

export default MapNavbar
