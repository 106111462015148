import React from 'react';

import './area_popup_content.css';


export default class AreaPopupContent extends React.Component {

  /*
   * React component to render in the area popup.
   *
   * props:
   *   layer_name: Name of the layer, to use as a subtitle in the popup.
   *   area_info_primary: An array of key-value pairs to render in the "About" table
   *   area_info_secondary: An array of key-value pairs to render below the fold in the "About" table.
   */
  constructor() {
    super()

    this.state = {
      expanded: false,
      showSecondaryRows: false
    }
    this.toggleRows = this.toggleRows.bind(this)
  }

  toggleRows() {
    if (this.state.showSecondaryRows) {
      this.setState({showSecondaryRows: false, expanded: false})
    }
    else {
      this.setState({showSecondaryRows: true, expanded: true})
    }
  }

  addFieldsToTable(item) {
    // If value is a number, check if it's a float and round it.
    var display_value = item[1]
    var num_value = Number(display_value);
    if (!isNaN(num_value) && num_value % 1 !== 0) {
      display_value = num_value.toFixed(2)
    }

    return (
      <tr className="area-about-tr" key={item[0]}>
        <td className="area-about-td">{item[0]}</td>
        <td className="area-about-td">{display_value}</td>
      </tr>
    );
  }

  render() {
    const layer_name = this.props.layer_name
    const area_info_primary = this.props.area_info_primary
    const area_info_secondary = this.props.area_info_secondary

    return <div className="area-popup">
      <h4 className="area-popup-header"><b>About Area</b></h4>
      <h6 className="area-popup-header">{layer_name}</h6>
      <table className="area-about-table"><tbody>
        {/* Add primary fields above the fold */}
        {area_info_primary.map(this.addFieldsToTable)}

        {/* Add secondary fields below the fold. */}
        {area_info_secondary !== undefined && area_info_secondary.length > 0 &&
          // Add the "show more" toggle
          <tr className="area-about-tr" key="toggle-btn">
            <td className="area-about-td">
              <a href="#!"
                className="blue-link-with-no-decor"
                onClick={this.toggleRows}
              >
                {this.state.expanded ? (
                  <span>&#x25B2; Show Less</span>
                ) : (
                  <span>&#x25BC; Show More</span>
                )}
              </a>
            </td>
            <td className="area-about-td" />
          </tr>
          // Add fields if toggled.
        }
        {this.state.expanded &&
          area_info_secondary.map(this.addFieldsToTable)
        }
      </tbody></table>
    </div>

  }

  prettyPrintArea(area) {
    return area.toLocaleString(
      undefined, {maximumFractionDigits: 2}
    );
  }

}
