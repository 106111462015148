import mapboxgl from '!mapbox-gl';  // eslint-disable-line import/no-webpack-loader-syntax


export default class CLAttributionControl extends mapboxgl.AttributionControl {
  constructor(...args) {
    return super({
      customAttribution: '<a href="/about" target="_blank">About this site</a>'
    });
  }
}
